/* styles.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 }
 
 body {
    font-family: 'Roboto', Arial, sans-serif;
    background-color: #eaeef1;
    color: #333;
    line-height: 1.6;
 }
 
 header {
    background-color: #f0f3f6;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 
 .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
 }
 
 .logo-text {
    font-size: 32px;
    font-weight: bold;
    color: #3a3f44;
 }
 
 .nav-tabs {
    position: relative;
 }
 
 .nav-list {
    display: flex;
    list-style: none;
    gap: 1.5rem;
 }
 
 .nav-list li a {
    text-decoration: none;
    color: #3a3f44;
    font-weight: bold;
    font-size: 18px;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
 }
 
 .nav-list li a:hover {
    background-color: #d4dae0;
    border-radius: 4px;
 }
 
 .hamburger {
    display: none;
    flex-direction: column;
    gap: 0.25rem;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
 }
 
 .hamburger span {
    width: 25px;
    height: 2px;
    background-color: #3a3f44;
    transition: transform 0.3s ease, opacity 0.3s ease;
 }
 
 .hamburger.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
 }
 
 .hamburger.active span:nth-child(2) {
    opacity: 0;
 }
 
 .hamburger.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
 }
 
 .presentation {
    margin: 2rem;
    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
 }
 
 .presentation h1 {
    font-size: 28px;
    font-weight: bold;
    color: #3a3f44;
    margin-bottom: 1rem;
 }
 
 .presentation p {
    color: #666;
    margin-bottom: 0.75rem;
 }
 
 .featured-articles {
    margin: 2rem;
    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
 }
 
 .featured-articles h2 {
    font-size: 28px;
    font-weight: bold;
    color: #3a3f44;
    margin-bottom: 1rem;
    text-align: center;
 }
 
 .articles-container {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
 }
 
 .article {
    flex: 1 1 45%;
    min-width: 45%;
    margin-bottom: 1.5rem;
 }
 
 .article iframe {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 }
 
 footer {
    background-color: #3a3f44;
    color: #ffffff;
    padding: 1.5rem;
    text-align: center;
    bottom: 0;
    width: 100%;
 }
 
 .footer-content {
    font-size: 16px;
 }
 
 .intro-text {
    text-align: center;
 }
 
 .about, .contact, .featured-articles {
    margin: 2rem;
    padding: 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
 }
 
 .about h2, .contact h2, .featured-articles h2 {
    font-size: 28px;
    font-weight: bold;
    color: #3a3f44;
    text-align: center;
    margin-bottom: 1rem;
 }
 
 .about p, .contact p {
    color: #666;
    margin-bottom: 1rem;
    line-height: 1.6;
 }
 
 .contact form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
 }
 
 .contact label {
    font-weight: bold;
    color: #3a3f44;
 }
 
 .contact input, .contact textarea {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
 }
 
 .contact button {
    padding: 0.75rem 1.5rem;
    background-color: #3a3f44;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
 }
 
 .contact button:hover {
    background-color: #555;
 }
 
 .contact a {
    color: #3a3f44;
    text-decoration: none;
 }
 
 .contact a:hover {
    text-decoration: underline;
 }
 
 .feature-cards {
    display: flex;
    gap: 1.5rem;
    margin: 0rem 2rem 0rem 2rem; /* Reduce bottom margin */
    flex-wrap: nowrap;
 }
 
 .card {
    position: relative;
    padding: 2rem;
    flex: 1 1 45%;
    min-width: 45%;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: inherit;
    transition: background-color 0.3s ease, border-color 0.3s ease;
 }
 
 .card:hover {
    background-color: #fff;
    border: 2px solid transparent;
 }
 
 .card-law {
    background-color: rgba(161, 219, 238, 0.5);
    border: 2px solid #7bace4;
    border-radius: 8px;
 }
 
 .card-nutrition {
    background-color: rgba(239, 158, 218, 0.5);
    border: 2px solid #ff67a6;
    border-radius: 8px;
 }
 
 .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    justify-content: center;
    padding: .5rem;
 }
 
 .underline_title:hover, .underline_glu:hover, .underline_jur:hover {
    text-decoration: underline;
 }
 
 .card h2 {
    font-size: 24px;
    font-weight: bold;
    color: #3a3f44;
    margin-bottom: 1rem;
    text-decoration: none;
 }
 
 .card h2 span {
    font-size: 32px;
    margin-right: 8px;
 }
 
 .card-description {
    color: #333;
    font-size: 16px;
 }
 
 @media (max-width: 800px) {
    .nav-list {
        display: none;
        flex-direction: column;
        background-color: #acb3bb;
        padding: 1rem;
        gap: 0.5rem;
        border-radius: 8px;
        position: absolute;
        right: 0;
        top: 1.5rem;
    }
 
    .nav-list.show {
        display: flex;
    }
 
    .hamburger {
        display: flex;
    }
 
    .presentation, .feature-cards, .featured-articles {
        margin: 1rem;
    }
 
    .feature-cards, .articles-container {
        flex-direction: column;
    }
 
    .article {
        min-width: 100%;
    }
 }

 
 