/* Header.css */
.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo-text {
    font-size: 32px;
    font-weight: bold;
    color: #3a3f44;
}

.nav-tabs {
    position: relative;
}

.nav-list {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.nav-list li a {
    text-decoration: none;
    color: #3a3f44;
    font-weight: bold;
    font-size: 18px;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
}

.nav-list li a:hover {
    background-color: #d4dae0;
    border-radius: 4px;
}

.hamburger {
    display: none;
    flex-direction: column;
    gap: 0.25rem;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    position: relative;
    z-index: 1;
}

.hamburger span {
    width: 25px;
    height: 2px;
    background-color: #3a3f44;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger.active span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
    opacity: 0;
}

.hamburger.active span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 800px) {
    .nav-list {
        display: none;
        flex-direction: column;
        background-color: #acb3bb;
        padding: 1rem;
        gap: 0.5rem;
        border-radius: 8px;
        position: absolute;
        right: 0;
        top: 1.5rem;
        transform: translateY(-20px);
        opacity: 0;
    }

    .nav-list.show {
        display: flex;
        transform: translateY(0);
        opacity: 1;
    }

    .hamburger {
        display: flex;
    }
}
