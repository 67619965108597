/* BlogPost.css */

/* General Styles */
.blog-post {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.blog-post-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blog-post-content {
    padding: 20px;
}

.blog-post h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.blog-post-meta {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.hashtags {
    margin-bottom: 20px;
}

.hashtag {
    color: #007BFF;
    margin-right: 10px;
    font-weight: bold;
}

.post-content {
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
}

/* Full Page */
@media (min-width: 1200px) {
    .blog-post {
        max-width: 1000px;
    }

    .blog-post-content {
        padding: 40px;
    }

    .blog-post h1 {
        font-size: 2.5em;
    }

    .post-content {
        font-size: 1.2em;
    }
}

/* Tablet Page */
@media (max-width: 1199px) and (min-width: 768px) {
    .blog-post {
        max-width: 700px;
    }

    .blog-post-content {
        padding: 30px;
    }

    .blog-post h1 {
        font-size: 2.2em;
    }

    .post-content {
        font-size: 1.1em;
    }
}

/* Mobile Page */
@media (max-width: 767px) {
    .blog-post {
        max-width: 100%;
    }

    .blog-post-content {
        padding: 20px;
    }

    .blog-post h1 {
        font-size: 1.8em;
    }

    .post-content {
        font-size: 1em;
    }
}

/* Miniature Page for Featured Articles */
.miniature-blog-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    margin: auto;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.miniature-blog-post-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.miniature-blog-post-content {
    padding: 10px;
    text-align: center;
}

.miniature-blog-post h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.miniature-hashtags {
    margin-bottom: 10px;
}

.miniature-hashtag {
    color: #007BFF;
    margin-right: 5px;
    font-weight: bold;
}
